import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Worker } from "src/app/core/interfaces/worker.interface";
import { ClearErrors, SetError } from "src/app/features/admin/admin.state";
import { NavigationItem } from "src/app/shared/interfaces/navigation-item.interface";
import { Pagination } from "src/app/shared/interfaces/pagination.interface";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { DashboardPageStateModel, DashboardPagesStateDefaults } from "src/app/shared/states/dashboard.state";
import { getUpdatedItems } from "src/app/shared/utils/utils";
import { WORKER_PROFILE_TABS } from "../consts/worker-profile-tabs.const";
import { WorkerProfileStatus } from "../enums/worker-profile-status.enum";
import { Incident } from "../interfaces/incident.model";
import { Suspension } from "../interfaces/suspension.model";
import { WorkerCertification } from "../interfaces/worker-certification.model";
import { WorkerCommunication } from "../interfaces/worker-communication.model";
import { WorkerDebit } from "../interfaces/worker-debit.interface";
import { WorkerDocument } from "../interfaces/worker-document.interface";
import { WorkerPayment } from "../interfaces/worker-payment.interface";
import { DebitsService } from "../services/debits.service";
import { DocumentsService } from "../services/documents.service";
import { IncidentsService } from "../services/incidents.service";
import { PaymentsService } from "../services/payments.service";
import { SuspensionsService } from "../services/suspensions.service";
import { WorkerService } from "../services/worker.service";
import {
  AddCertificationToWorker,
  AddWorker,
  AddWorkerDebit,
  AddWorkerDocument,
  AddWorkerIncident,
  AddWorkerPayment,
  AddWorkerSuspension,
  DeleteWorkerImage,
  DeleteWorkerUnions,
  EditCertificationToWorker,
  GetWorkerById,
  GetWorkerCertifications,
  GetWorkerCommunications,
  GetWorkerIncidents,
  GetWorkerProfileHistory,
  LoadIncidentTypes,
  LoadWorkerDebits,
  LoadWorkerDocuments,
  LoadWorkerPayments,
  LoadWorkerSuspensions,
  LoadWorkerTabs,
  RemoveCertificationToWorker,
  RemoveWorkerDebit,
  RemoveWorkerDocument,
  RemoveWorkerIncident,
  RemoveWorkerPayment,
  RemoveWorkerSuspension,
  UpdateTabsAddWorker,
  UpdateWorker,
  UpdateWorkerDebit,
  UpdateWorkerDocument,
  UpdateWorkerExperience,
  UpdateWorkerImage,
  UpdateWorkerIncident,
  UpdateWorkerPayment,
  UpdateWorkerSuspension,
  UpdateWorkerUnions
} from "./worker.actions";

export interface WorkerStateModel extends DashboardPageStateModel {
  selectedWorker: any;
  workerTabs: Array<NavigationItem>;

  workerSuspensions?: Suspension[]
	paginationSuspensions?: Pagination;
	totalWorkerSuspensions?: number;

	workerPayments: WorkerPayment[];
	totalWorkerPayments: number;

	workerDebits: WorkerDebit[];
	totalWorkerDebits: number;

  workerIncidents?: Incident[];
	incidentTypes?: SelectOption[];

	workerCertifications?: WorkerCertification[];

	workerCommunications?: WorkerCommunication[];

  workerProfileHistory?: any;

	workerDocuments?: WorkerDocument[];
	totalWorkerDocuments?: number;
}

@State<WorkerStateModel>({
  name: "worker",
  defaults: {
    ...DashboardPagesStateDefaults,
    selectedWorker: undefined,
    workerPayments: [],
		totalWorkerPayments: 0,
    workerDebits: [],
		totalWorkerDebits: 0,
		workerIncidents: [],
		workerSuspensions: [],
		paginationSuspensions: null,
		totalWorkerSuspensions: 0,
    workerProfileHistory: [],
		workerDocuments: [],
		totalWorkerDocuments: 0,
		workerTabs: []
  }
})
@Injectable()
export class WorkerState {

  constructor(
    private workerService: WorkerService,
    private suspensionService: SuspensionsService,
		private incidentService: IncidentsService,
		private paymentService: PaymentsService,
		private debitsService: DebitsService,
		private documentsService: DocumentsService
  ) {}

  @Selector() static loading(state: WorkerStateModel) { return state.loading; }
	@Selector() static saving(state: WorkerStateModel) { return state.saving; }
	@Selector() static loaded(state: WorkerStateModel) { return state.loaded; }
  @Selector() static selectedWorker(state: WorkerStateModel) { return state.selectedWorker; }
	@Selector() static workerIncidents(state: WorkerStateModel) { return state.workerIncidents; }
	@Selector() static incidentTypes(state: WorkerStateModel) { return state.incidentTypes; }
	@Selector() static workerCertifications(state: WorkerStateModel) { return state.workerCertifications; }
	@Selector() static workerProfileHistory(state: WorkerStateModel) { return state.workerProfileHistory; }
	@Selector() static workerCommunications(state: WorkerStateModel) { return state.workerCommunications; }

	@Selector() static workerSuspensions(state: WorkerStateModel) { return state.workerSuspensions; }
	@Selector() static totalWorkerSuspensions(state: WorkerStateModel) { return state.totalWorkerSuspensions; }

	@Selector() static workerPayments(state: WorkerStateModel) { return state.workerPayments; }
	@Selector() static totalWorkerPayments(state: WorkerStateModel) { return state.totalWorkerPayments; }

	@Selector() static workerDebits(state: WorkerStateModel) { return state.workerDebits; }
	@Selector() static totalWorkerDebits(state: WorkerStateModel) { return state.totalWorkerDebits; }

	@Selector() static workerDocuments(state: WorkerStateModel) { return state.workerDocuments; }
	@Selector() static totalWorkerDocuments(state: WorkerStateModel) { return state.totalWorkerDocuments; }

	@Selector() static workerTabs(state: WorkerStateModel) { return state.workerTabs; }

  @Action(AddWorker)
	addWorker(ctx: StateContext<WorkerStateModel>, action: AddWorker) {
		ctx.patchState({ saving: true });
		ctx.dispatch(new ClearErrors());
		return this.workerService.create(action.worker).subscribe(
			(response) => {
				action.callbackSuccess(response);
				ctx.patchState({
          saving: false,
          selectedWorker: JSON.stringify(response)
        });
			},
			(error) => {
				action.callbackError(error);
				ctx.dispatch(new SetError({ saving: true }));
				ctx.patchState({ saving: false });
			}
		);
	}

  @Action(UpdateWorker)
	updateWorker(ctx: StateContext<WorkerStateModel>, action: UpdateWorker) {
    ctx.patchState({ saving: true });

		return this.workerService.update(action.worker).subscribe(
			(response) => {
				action.callbackSuccess();
				const state = ctx.getState();

				const keys: string[] = Object.keys(action.worker);
				const selectedWorker: Worker = JSON.parse(state.selectedWorker);

				keys.forEach(k => {
					if (selectedWorker.hasOwnProperty(k)) {
						selectedWorker[k] = response[k]
					}
				});
				
				ctx.patchState({
					saving: false,
					selectedWorker: JSON.stringify(selectedWorker)
				});
			},
			(error) => {
				action.callbackError();
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerImage)
	updateWorkerImage(ctx: StateContext<WorkerStateModel>, action: UpdateWorkerImage) {
		return this.workerService.uploadImage(action.workerId, action.file).subscribe(
			(response) => {
				action.callbackSuccess();
				const state = ctx.getState();

				const selectedWorker: Worker = JSON.parse(state.selectedWorker);
				selectedWorker.profileImageUri = `${response.fileUri}?${new Date().getTime()}`;
				
				ctx.patchState({
					saving: false,
					selectedWorker: JSON.stringify(selectedWorker)
				});

			},
			(error) => {
				action.callbackError();
			}
		);
	}

	@Action(DeleteWorkerImage)
	deleteWorkerImage(ctx: StateContext<WorkerStateModel>, action: DeleteWorkerImage) {
		return this.workerService.removeImage(action.workerId).subscribe(
			(response) => {
				action.callbackSuccess();
				const state = ctx.getState();

				const selectedWorker: Worker = JSON.parse(state.selectedWorker);
				selectedWorker.profileImageUri = null;
				
				ctx.patchState({
					saving: false,
					selectedWorker: JSON.stringify(selectedWorker)
				});

			},
			(error) => {
				action.callbackError();
			}
		);
	}

  @Action(GetWorkerById)
	getWorkerById(ctx: StateContext<WorkerStateModel>, { id }) {
		ctx.patchState({
			loading: true,
			loaded: false,
			selectedWorker: undefined,
			workerSuspensions: undefined
		});

		return this.workerService.getWorkerById(id).pipe(
			tap(
				(response) => {
					ctx.patchState({
						selectedWorker: JSON.stringify(response),
						loading: false,
						loaded: true
					});
				},
				(error) => {
					ctx.patchState({ selectedWorker: null, loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(UpdateWorkerExperience)
	updateWorkerExperience(ctx: StateContext<WorkerStateModel>, { workerId, workerExperience, callback }) {
		const state = ctx.getState();

		return this.workerService.updateWorkerExperience(workerId, workerExperience).subscribe(
			profile => {
				callback();
				ctx.patchState({
					// items: getUpdatedItems(profile, state.items),
					saving: false,
					selectedWorker: JSON.stringify(profile)
				});
			},
			error => {
				callback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerUnions)
	updateWorkerUnions(ctx: StateContext<WorkerStateModel>, { workerId, workerUnions, callback }) {
		return this.workerService.updateOrCreateWorkerUnions(workerId, workerUnions).subscribe(
			profile => {
				callback();
				ctx.patchState({
					saving: false,
					selectedWorker: JSON.stringify(profile)
				});
			},
			error => {
				callback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(LoadWorkerSuspensions)
	loadWorkerSuspensions(ctx: StateContext<WorkerStateModel>, {payload}) {
		ctx.patchState({
			workerSuspensions: [],
			totalWorkerSuspensions: 0,
			loading: true
		});

		return this.suspensionService.getSuspensionsByWorker(payload.workerId, payload.maxResult, payload.skipCount).subscribe(
			(response) => {
				ctx.patchState({
					workerSuspensions: response.items,
					totalWorkerSuspensions: response.totalCount,
					loading: false,
					paginationSuspensions: {
						maxResult: payload.maxResult,
						skipCount: payload.skipCount
					}
				});
			},
			(error) => {
				ctx.patchState({
					workerSuspensions: [],
					totalWorkerSuspensions: 0,
					loading: false
				});
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(AddWorkerSuspension)
	addWorkerSuspension(ctx: StateContext<WorkerStateModel>, { payload, files, dialogCallback }) {
		ctx.patchState({ saving: true });

		const reload: boolean = payload.employerIds?.length > 1 || payload.unionIds?.length > 1;

		return this.suspensionService.create(payload).subscribe(
			(response) => {
				if (files?.length) {
					this.suspensionService.addFiles(response.id, files).then(
						(responseFiles) => {
							dialogCallback();
							response.suspensionFiles = responseFiles;
							this.updateSuspensionAfterCreate(response, ctx, reload);
						},
						(error) => {
							dialogCallback();
							this.updateSuspensionAfterCreate(response, ctx, reload);
						}
					)
				} else {
					dialogCallback();
					this.updateSuspensionAfterCreate(response, ctx, reload);
				}
			},
			(error) => {
				dialogCallback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	private updateSuspensionAfterCreate(newSuspension: Suspension, ctx: StateContext<WorkerStateModel>, reload: boolean): void {
		const state = ctx.getState();
		if (reload) {
			// It reload suspension list when create a "multiple suspension" (selected 'All Employers' or 'All Unions').
			// Because backend return one suspension but create more than one.
			ctx.dispatch(
				new LoadWorkerSuspensions({
					workerId: JSON.parse(state.selectedWorker).id,
					maxResult: state.paginationSuspensions.maxResult,
					skipCount: state.paginationSuspensions.skipCount
				})
			);
		} else {
			ctx.patchState({
				workerSuspensions: [newSuspension, ...state.workerSuspensions],
				totalWorkerSuspensions: state.totalWorkerSuspensions + 1
			});
		}
		ctx.patchState({ saving: false });
	}

	@Action(UpdateWorkerSuspension)
	updateWorkerSuspension(ctx: StateContext<WorkerStateModel>, { payload, files, filesToDelete, dialogCallback }) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.suspensionService.update(payload).subscribe(
			(suspension) => {
				const promises: Array<Promise<any>> = [];
				if (files.length) {
					promises.push(this.suspensionService.addFiles(payload.id, files));
				}
				if (filesToDelete.length) {
					promises.push(this.suspensionService.removeFiles(filesToDelete));
				}

				if (promises.length) {
					Promise.all(promises).then(
						(response) => {
							this.suspensionService.getSuspensionById(payload.id).then(
								(response) => {
									dialogCallback(false);
									ctx.patchState({ workerSuspensions: getUpdatedItems(response, state.workerSuspensions), saving: false });
								},
								(error) => {
									dialogCallback(error);
									ctx.patchState({ workerSuspensions: getUpdatedItems(suspension, state.workerSuspensions), saving: false });
								}
							)
						},
						(error) => {
							dialogCallback(error);
							ctx.patchState({ workerSuspensions: getUpdatedItems(suspension, state.workerSuspensions), saving: false });
						}
					)
				} else {
					dialogCallback(false);
					ctx.patchState({ workerSuspensions: getUpdatedItems(suspension, state.workerSuspensions), saving: false });
				}
			},
			(error) => {
				dialogCallback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(RemoveWorkerSuspension)
	removeWorkerSuspension(ctx: StateContext<WorkerStateModel>, { payload, dialogCallback }) {
		return this.suspensionService.remove(payload).subscribe(
			() => {
				dialogCallback();
				const state = ctx.getState();
				ctx.patchState({
					workerSuspensions: state.workerSuspensions.filter(item => {
						return item.id !== payload.id;
					}),
					totalWorkerSuspensions: state.totalWorkerSuspensions - 1,
				});
			},
			error => {
				dialogCallback(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}

	@Action(GetWorkerIncidents)
	getWorkerIncidents(ctx: StateContext<WorkerStateModel>, { workerId }) {
		ctx.patchState({ workerIncidents: [], loading: true });

		return this.incidentService.getIncidentsByWorkerId(workerId).subscribe(
			(response) => {
				ctx.patchState({
					workerIncidents: response.items,
					loading: false
				});
			},
			error => {
				ctx.patchState({ workerIncidents: [], loading: false });
			}
		);
	}

	@Action(AddWorkerIncident)
	addWorkerIncident(ctx: StateContext<WorkerStateModel>, { payload, files, dialogCallback }) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.incidentService.create(payload).then(
			(response) => {
				if (files?.length) {
					this.incidentService.addFiles(response.id, files).then(
						(responseFiles) => {
							dialogCallback();
							response.workerIncidentFiles = responseFiles;
							ctx.patchState({ workerIncidents: [response, ...state.workerIncidents], saving: false });
						},
						(error) => {
							dialogCallback();
							ctx.patchState({ workerIncidents: [response, ...state.workerIncidents], saving: false });
						}
					)
				} else {
					dialogCallback();
					ctx.patchState({ workerIncidents: [response, ...state.workerIncidents], saving: false });
				}
			},
			(error) => {
				dialogCallback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerIncident)
	updateWorkerIncident(ctx: StateContext<WorkerStateModel>, { payload, files, filesToDelete, dialogCallback }) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.incidentService.update(payload).subscribe(
			(incident) => {
				const promises: Array<Promise<any>> = [];
				if (files.length) {
					promises.push(this.incidentService.addFiles(payload.id, files));
				}
				if (filesToDelete.length) {
					promises.push(this.incidentService.removeFiles(filesToDelete));
				}
				if (promises.length) {
					Promise.all(promises).then(
						(response) => {
							this.incidentService.getIncidentsById(payload.id).then(
								(response) => {
									dialogCallback(false);
									ctx.patchState({ workerIncidents: getUpdatedItems(response, state.workerIncidents), saving: false });
								},
								(error) => {
									dialogCallback(error);
									ctx.patchState({ workerIncidents: getUpdatedItems(incident, state.workerIncidents), saving: false });
								}
							)
						},
						(error) => {
							dialogCallback(error);
							ctx.patchState({ workerIncidents: getUpdatedItems(incident, state.workerIncidents), saving: false });
						}
					)
				} else {
					dialogCallback(false);
					ctx.patchState({ workerIncidents: getUpdatedItems(incident, state.workerIncidents), saving: false });
				}
			},
			(error) => {
				dialogCallback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(RemoveWorkerIncident)
	removeWorkerIncident(ctx: StateContext<WorkerStateModel>, { payload, dialogCallback }) {
		return this.incidentService.remove(payload).subscribe(
			() => {
				dialogCallback();
				const state = ctx.getState();
				ctx.patchState({
					workerIncidents: state.workerIncidents.filter(item => {
						return item.id !== payload.id;
					})
				});
			},
			error => {
				dialogCallback(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}

	@Action(LoadIncidentTypes)
	loadIncidentTypes(ctx: StateContext<WorkerStateModel>) {
		ctx.patchState({
      incidentTypes: []
    });

    return this.incidentService.getIncidentTypes().pipe(
      tap(
        (response) => {
          ctx.patchState({
            incidentTypes: response
          });
        }
      )
    );  
	}

	@Action(GetWorkerCertifications)
	getWorkerCertifications(ctx: StateContext<WorkerStateModel>, { workerId }) {
		ctx.patchState({
      loading: true,
      workerCertifications: []
    });

		return this.workerService.getWorkerCertifications(workerId).subscribe(
			(res: WorkerCertification[]) => {
				ctx.patchState({
          workerCertifications: res,
          loading: false
        });
			},
			error => {
				ctx.patchState({
          workerCertifications: [],
          loading: false
        });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(AddCertificationToWorker)
	addCertificationToWorker(ctx: StateContext<WorkerStateModel>, { workerId, certification, callback }) {
		ctx.patchState({ saving: true });
		ctx.dispatch(new ClearErrors());
		return this.workerService.addCertificationToWorker(workerId, certification.id).subscribe(
			res => {
				callback();
				ctx.patchState({
          workerCertifications: res,
          saving: false
        });
			},
			error => {
				callback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: true }));
			}
		);
	}

	@Action(DeleteWorkerUnions)
	deleteWorkerUnions(ctx: StateContext<WorkerStateModel>, { workerUnionId, callback }) {
		return this.workerService.deleteWorkerUnionId(workerUnionId).pipe(
			tap(
				(response) => {
					callback();
					ctx.patchState({
						saving: false
					});
				},
				(error) => {
					callback(error);
					ctx.patchState({ saving: false });
					ctx.dispatch(new SetError({ saving: error }));
				}
			)
		);
	}

	@Action(GetWorkerCommunications)
	getWorkerCommunications(ctx: StateContext<WorkerStateModel>, { workerId }) {
		ctx.patchState({
      loading: true,
      workerCommunications: []
    });

		return this.workerService.getWorkerCommuncations(workerId).subscribe(
			res => {
				ctx.patchState({
					workerCommunications: res.items.map((c) => {
						c.creationTime = new Date(c.creationTime + '+0000');
						return c;
					}),
					loading: false
				});
			},
			error => {
				ctx.patchState({ workerCommunications: [], loading: false });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

  @Action(EditCertificationToWorker)
	editCertificationToWorker(ctx: StateContext<WorkerStateModel>, action: EditCertificationToWorker) {
		ctx.patchState({ saving: true });
		ctx.dispatch(new ClearErrors());
		return this.workerService.editCertificationToWorker(action.workerCertificatioId, action.certificationId, action.workerCertification).subscribe(
			(response) => {
				action.callback(null);
				ctx.patchState({
          workerCertifications: response,
          saving: false
        });
			},
			(error) => {
				action.callback(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: true }));
			}
		);
	}

	@Action(RemoveCertificationToWorker)
	removeCertificationToWorker(ctx: StateContext<WorkerStateModel>, { workerCertificationId, workerId, certificationId, callback }) {
		ctx.patchState({ saving: true });
		ctx.dispatch(new ClearErrors());
		return this.workerService.removeCertificationToWorker(workerCertificationId, workerId, certificationId).pipe(
      tap(
        res => {
          callback();
          ctx.patchState({ workerCertifications: res, saving: false });
        },
        error => {
          ctx.patchState({ saving: false });
          ctx.dispatch(new SetError({ saving: true }));
        }
      )
    );
	}

	@Action(LoadWorkerTabs)
	loadWorkerTabs(ctx: StateContext<WorkerStateModel>, action: LoadWorkerTabs) {
		const tabs: NavigationItem[] = [...WORKER_PROFILE_TABS[action.status]];
		ctx.patchState({
			workerTabs: tabs
		});
	}

	@Action(UpdateTabsAddWorker)
	updateTabsAddWorker(ctx: StateContext<WorkerStateModel>, { profile, workerInformation }) {
		const updatedWorkerTabs: Array<NavigationItem> = [...WORKER_PROFILE_TABS[WorkerProfileStatus.Creating]].map((t) => {
			let tab: NavigationItem = Object.assign({}, t);
			if (tab.routerLink.includes("profile")) {
				tab.disabled = profile;
			} else if (tab.routerLink.includes("worker-information")) {
				tab.disabled = workerInformation;
			}
			return tab;
		})

		ctx.patchState({ workerTabs: updatedWorkerTabs });
	}

  @Action(LoadWorkerPayments)
	loadWorkerPayments(ctx: StateContext<WorkerStateModel>, { workerId, maxResults, skipCount }) {
		ctx.patchState({ workerSuspensions: [], loading: true });

		return this.paymentService.getPaymentsByWorkerId(workerId, maxResults, skipCount).pipe(
			tap(
				(response) => {
					ctx.patchState({
						workerPayments: response.items,
						totalWorkerPayments: response.totalCount,
						loading: false
					});
				},
				(error) => {
					ctx.patchState({
						workerSuspensions: [],
						totalWorkerSuspensions: 0,
						loading: false
					});
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(AddWorkerPayment)
	addWorkerPayment(ctx: StateContext<WorkerStateModel>, { payload, callbackSuccess, callbackError }) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.paymentService.createPayment(payload).then(
			(response) => {
				callbackSuccess();
				ctx.patchState({
					workerPayments: [response, ...state.workerPayments],
					totalWorkerPayments: state.totalWorkerPayments + 1,
					saving: false
				});
			},
			(error) => {
				callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerPayment)
	updateWorkerPayment(ctx: StateContext<WorkerStateModel>, { payload, callbackSuccess, callbackError }) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.paymentService.updatePayment(payload).then(
			(response) => {
				callbackSuccess(false);
				ctx.patchState({
					workerPayments: getUpdatedItems(response, state.workerPayments),
					saving: false
				});
			},
			(error) => {
				callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(RemoveWorkerPayment)
	removeWorkerPayment(ctx: StateContext<WorkerStateModel>, { paymentId, callbackSuccess, callbackError }) {
		return this.paymentService.removePayment(paymentId).then(
			(response) => {
				callbackSuccess(response);
				const state = ctx.getState();
				ctx.patchState({
					workerPayments: state.workerPayments.filter((item) => {
						return item.id !== paymentId;
					}),
					totalWorkerPayments: state.totalWorkerPayments - 1,
				});
			},
			(error) => {
				callbackError(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}

	@Action(LoadWorkerDebits)
	loadWorkerDebits(ctx: StateContext<WorkerStateModel>, action: LoadWorkerDebits) {
		ctx.patchState({ workerDebits: [], loading: true });

		return this.debitsService.getDebitsByWorkerId(action.workerId, action.maxResults, action.skipCount).pipe(
			tap(
				(response) => {
					ctx.patchState({
						workerDebits: response.items,
						totalWorkerDebits: response.totalCount,
						loading: false
					});
				},
				(error) => {
					ctx.patchState({
						workerDebits: [],
						totalWorkerDebits: 0,
						loading: false
					});
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(AddWorkerDebit)
	addWorkerDebit(ctx: StateContext<WorkerStateModel>, action: AddWorkerDebit) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.debitsService.createDebit(action.payload).then(
			(response) => {
				action.callbackSuccess();
				ctx.patchState({
					workerDebits: [response, ...state.workerDebits],
					totalWorkerDebits: state.totalWorkerDebits + 1,
					saving: false
				});
			},
			(error) => {
				action.callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerDebit)
	updateWorkerDebit(ctx: StateContext<WorkerStateModel>, action: UpdateWorkerDebit) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.debitsService.updateDebit(action.payload).then(
			(response) => {
				action.callbackSuccess();
				ctx.patchState({
					workerDebits: getUpdatedItems(response, state.workerDebits),
					saving: false
				});
			},
			(error) => {
				action.callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(RemoveWorkerDebit)
	removeWorkerDebit(ctx: StateContext<WorkerStateModel>, action: RemoveWorkerDebit) {
		return this.debitsService.removeDebit(action.debitId).then(
			(response) => {
				action.callbackSuccess(response);
				const state = ctx.getState();
				ctx.patchState({
					workerDebits: state.workerDebits.filter((item) => {
						return item.id !== action.debitId;
					}),
					totalWorkerDebits: state.totalWorkerDebits - 1,
				});
			},
			(error) => {
				action.callbackError(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}

	@Action(LoadWorkerDocuments)
	loadWorkerDocuments(ctx: StateContext<WorkerStateModel>, action: LoadWorkerDocuments) {
		ctx.patchState({ workerDocuments: [], loading: true });

		return this.documentsService.getDocumentsByWorkerId(action.workerId, action.maxResults, action.skipCount).pipe(
			tap(
				(response) => {
					ctx.patchState({
						workerDocuments: response.items,
						totalWorkerDocuments: response.totalCount,
						loading: false
					});
				},
				(error) => {
					ctx.patchState({
						workerDocuments: [],
						totalWorkerDocuments: 0,
						loading: false
					});
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(AddWorkerDocument)
	addWorkerDocument(ctx: StateContext<WorkerStateModel>, action: AddWorkerDocument) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.documentsService.createDocument(action.payload).then(
			(response) => {
				this.documentsService.addFiles(response.id, action.files[0]).then(
					(responseFiles) => {
						action.callbackSuccess();
						response.workerDocumentFiles = responseFiles;
						ctx.patchState({
							workerDocuments: [response, ...state.workerDocuments],
							totalWorkerDocuments: state.totalWorkerDocuments + 1,
							saving: false
						});
					},
					(error) => {
						action.callbackSuccess();
						ctx.patchState({
							workerDocuments: [response, ...state.workerDocuments],
							totalWorkerDocuments: state.totalWorkerDocuments + 1,
							saving: false
						});
					}
				)
			},
			(error) => {
				action.callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(UpdateWorkerDocument)
	updateWorkerDocument(ctx: StateContext<WorkerStateModel>, action: UpdateWorkerDocument) {
		const state = ctx.getState();
		ctx.patchState({ saving: true });

		return this.documentsService.updateDocument(action.payload).then(
			(document) => {
				const promises: Array<Promise<any>> = [];
				if (action.files.length) {
					promises.push(this.documentsService.addFiles(action.payload.id, action.files[0]));
				}
				if (action.filesToDelete.length) {
					promises.push(this.documentsService.removeFiles(action.filesToDelete));
				}
				if (promises.length) {
					Promise.all(promises).then(
						(response) => {
							this.documentsService.getDocumentById(action.payload.id).then(
								(response) => {
									action.callbackSuccess();
									ctx.patchState({
										workerDocuments: getUpdatedItems(response, state.workerDocuments),
										saving: false
									});
								},
								(error) => {
									action.callbackError(error);
									ctx.patchState({
										workerDocuments: getUpdatedItems(document, state.workerDocuments),
										saving: false
									});
								}
							)
						},
						(error) => {
							action.callbackError(error);
							ctx.patchState({
								workerDocuments: getUpdatedItems(document, state.workerDocuments),
								saving: false
							});
						}
					)
				} else {
					action.callbackSuccess();
					ctx.patchState({
						workerDocuments: getUpdatedItems(document, state.workerDocuments),
						saving: false
					});				}
			},
			(error) => {
				action.callbackError(error);
				ctx.patchState({ saving: false });
				ctx.dispatch(new SetError({ saving: error }));
			}
		);
	}

	@Action(RemoveWorkerDocument)
	removeWorkerDocument(ctx: StateContext<WorkerStateModel>, action: RemoveWorkerDocument) {
		return this.documentsService.removeDocument(action.documentId).then(
			(response) => {
				action.callbackSuccess();
				const state = ctx.getState();
				ctx.patchState({
					workerDocuments: state.workerDocuments.filter((item) => {
						return item.id !== action.documentId;
					}),
					totalWorkerDocuments: state.totalWorkerDocuments - 1,
				});
			},
			(error) => {
				action.callbackError(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}
  
  @Action(GetWorkerProfileHistory)
	getWorkerProfileHistory(ctx: StateContext<WorkerStateModel>, { workerId }) {
		ctx.patchState({ loading: true });

		return this.workerService.getWorkerProfileHistory(workerId).subscribe(
			res => {
				ctx.patchState({
					workerProfileHistory: res.items.map((h) => {
						h.creationTime = new Date(h.creationTime + '+0000');
						return h;
					}),
					loading: false
				});
			},
			error => {
				ctx.patchState({ loading: false });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

}