<h3 class="font-extrabold mb-6">{{ title }}</h3>

<form (ngSubmit)="save()" [formGroup]="debitForm" class="overflow-x-hidden">
	<fieldset [disabled]="isSaving$ | async">
    <div class="grid grid-cols-3 mb-4 gap-3">
        <app-dropdown [placeholder]="'Select Debit Type'"
                      [required]="true"
                      [label]="'Debit Type'"
                      [fullWidth]="true"
                      [options]="debitTypesOptions"
                      [formControl]="debitForm.get('debitTypeId')"
                      class="block" />

        <app-text-input [placeholder]="'Select Amount'"
                        [label]="'Amount'"
                        type="text"
                        [required]="true"
                        [mask]="'0*.99'"
                        [dropSpecialCharacters]="false"
                        [formControl]="debitForm.get('amount')"
                        class="block" />

        <app-dropdown [placeholder]="'Select Dollar or Percent'"
                      [required]="true"
                      [label]="'Dollar or Percent'"
                      [fullWidth]="true"
                      [options]="debitAmountTypeOptions"
                      [formControl]="debitForm.get('debitAmountType')"
                      class="block" />

		</div>
    <div class="grid grid-cols-3 mb-4 gap-3">
      <app-dropdown
        [placeholder]="'Select Repeating or One Time'"
        [required]="true"
        [label]="'Repeating or One Time'"
        [fullWidth]="true"
        [options]="debitFrequencyOptions"
        [formControl]="debitForm.get('debitFrequency')"
        class="block" />

      @if (debitForm.get('debitFrequency').value === DebitFrequency.OneTime) {
        <app-date-picker
          [placeholder]="'Select Payment Date'"
          [label]="'Payment Date'"
          [required]="true"
          [formControl]="debitForm.get('paymentDate')"
          class="block" />
      }
    </div>

    @if (debitForm.get('debitFrequency').value === DebitFrequency.Repeating) {
      <div class="grid grid-cols-3 mb-4 gap-3">
        <app-dropdown
          [placeholder]="'Select Frequency'"
          [required]="true"
          [label]="'Frequency'"
          [fullWidth]="true"
          [options]="debitFrequencyTypeOptions"
          [formControl]="debitForm.get('debitFrequencyType')"
          class="block" />
  
        <app-date-picker
          [placeholder]="'Select Effective Start Date'"
          [label]="'Effective Start Date'"
          [formControl]="debitForm.get('effectiveStartDate')"
          class="block" />
  
        <app-date-picker
          [placeholder]="'Select Effective End Date'"
          [label]="'Effective End Date'"
          [formControl]="debitForm.get('effectiveEndDate')"
          class="block" /> 
      </div>
    }
    
		<div class="grid grid-cols-6">
			<label class="col-span-6 base-input-label w-full mt-2 mr-1">
        Notes
        <textarea formControlName="notes"
                  cols="40"
                  placeholder="Type Notes"
                  maxLength="500"
                  class="border-2 rounded mt-1 text-gray-600 p-4">
				</textarea>
      </label>
		</div>
		<app-dialog-footer [isSaving]="isSaving$ | async"
                         [submitActionName]="data.debit ? 'Save' : 'Add'"
                         [valid]="debitForm.valid"
                         (cancelAction)="closeDialog()" />
	</fieldset>
</form>
