import { Worker } from "src/app/core/interfaces/worker.interface";
import { Certification } from "src/app/features/admin/certifications/models/certification.model";
import { WorkerExperience } from "../../roster/interfaces/worker-experience.interface";
import { WorkerUnions } from "../../roster/interfaces/worker-union.interface";
import { WorkerProfileStatus } from "../enums/worker-profile-status.enum";
import { Incident } from "../interfaces/incident.model";
import { Suspension } from "../interfaces/suspension.model";
import { WorkerCertification } from "../interfaces/worker-certification.model";
import { WorkerDebit } from "../interfaces/worker-debit.interface";
import { WorkerDocument } from "../interfaces/worker-document.interface";
import { WorkerPayment } from "../interfaces/worker-payment.interface";

export class AddWorker {
	public static readonly type = "[Workers] add worker";
	constructor(
    public worker: Worker, 
    public callbackSuccess: (response) => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateWorker {
	public static readonly type = "[Worker] update worker";
	constructor(
    public worker: Worker, 
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateWorkerImage {
	public static readonly type = "[Worker] update worker image";
	constructor(
		public workerId: number,
		public file: File,
		public callbackSuccess: () => void,
		public callbackError: () => void,
	) {}
}

export class DeleteWorkerImage {
	public static readonly type = "[Worker] delete worker image";
	constructor(
		public workerId: number,
		public callbackSuccess: () => void,
		public callbackError: () => void,
	) {}
}

export class GetWorkerById {
	public static readonly type = "[Workers] load worker by id";
	constructor(
    public id: number
  ) {}
}

export class UpdateWorkerExperience {
	public static readonly type = "[Worker] update worker Experience";
	constructor(
		public workerId: number,
		public workerExperience: WorkerExperience[],
		public callback: (error) => void
	) {}
}

//// WORKER UNIONS ////
export class UpdateWorkerUnions {
	public static readonly type = "[Worker] update worker Unions";
	constructor(
		public workerId: number,
		public workerUnions: WorkerUnions[],
		public callback: (error) => void
	) {}
}

export class DeleteWorkerUnions {
	public static readonly type = "[Workers] delete worker union";
	constructor(
    public workerUnionId: string, 
    public callback: (error) => void
  ) {}
}

//// SUSPENSIONS ////
export class LoadWorkerSuspensions {
	public static readonly type = "[Suspension] load worker suspensions";
	constructor(
    public payload: any
  ) {}
}

export class AddWorkerSuspension {
	public static readonly type = "[Workers] add worker suspension";
	constructor(
		public payload: Suspension,
		public files: Array<File>,
		public dialogCallback: (error) => void
	) {}
}

export class UpdateWorkerSuspension {
	public static readonly type = "[Workers] update worker suspension";
	constructor(
		public payload: Suspension,
		public files: Array<File>,
		public filesToDelete: Array<string>,
		public dialogCallback: (error) => void
	) {}
}

export class RemoveWorkerSuspension {
	public static readonly type = "[Workers] remove worker suspension";
	constructor(
    public payload: Suspension, 
    public dialogCallback: () => void
  ) {}
}

//// INCIDENTS ////
export class GetWorkerIncidents {
	public static readonly type = "[Workers] load worker indicents";
	constructor(
    public workerId: number
  ) {}
}

export class AddWorkerIncident {
	public static readonly type = "[Workers] add worker indicent";
	constructor(
		public payload: Incident,
		public files: Array<File>,
		public dialogCallback: (error) => void
	) {}
}

export class UpdateWorkerIncident {
	public static readonly type = "[Workers] update worker indicent";
	constructor(
		public payload: Incident,
		public files: Array<File>,
		public filesToDelete: Array<string>,
		public dialogCallback: (error) => void
	) {}
}

export class RemoveWorkerIncident {
	public static readonly type = "[Workers] remove worker indicent";
	constructor(
    public payload: Incident, 
    public dialogCallback: (error) => void
  ) {}
}

export class LoadIncidentTypes {
	public static readonly type = "[Incidents] load incident types";
}

//// CERTIFICATIONS ////
export class GetWorkerCertifications {
	public static readonly type = "[Certifications] load worker certifications";
	constructor(
    public workerId: number
  ) {}
}

export class AddCertificationToWorker {
	public static readonly type = "[Certifications] add certification to worker";
	constructor(
		public workerId: number,
		public certification: Certification,
		public callback: (error) => void
	) {}
}

export class EditCertificationToWorker {
	public static readonly type = "[Certifications] edit worker certification";
	constructor(
		public workerCertificatioId: string,
		public workerCertification: WorkerCertification,
		public certificationId: string,
		public callback: (error) => void
	) {}
}

export class RemoveCertificationToWorker {
	public static readonly type = "[Certifications] remove certification to worker";
	constructor(
		public workerCertificationId: string,
		public workerId: number,
		public certificationId: string,
		public callback: (error) => void
	) { }
}

//// DEBITS ////
export class LoadWorkerDebits {
	public static readonly type = "[Worker Debits] load worker debits";
	constructor(
		public workerId: number,
		public maxResults: number,
		public skipCount: number
	){}
}

export class AddWorkerDebit {
	public static readonly type = "[Worker Debits] add worker debit";
	constructor(
		public payload: WorkerDebit,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class UpdateWorkerDebit {
	public static readonly type = "[Worker Debits] update worker debit";
	constructor(
		public payload: WorkerDebit,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class RemoveWorkerDebit {
	public static readonly type = "[Worker Debits] remove worker debit";
	constructor(
		public debitId: string,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void,
	) { }
}

//// PAYMENTS ////
export class LoadWorkerPayments {
	public static readonly type = "[Worker Payments] load worker payments";
	constructor(
		public workerId: number,
		public maxResults: number,
		public skipCount: number
	){}
}

export class AddWorkerPayment {
	public static readonly type = "[Worker Payments] add worker payment";
	constructor(
		public payload: WorkerPayment,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class UpdateWorkerPayment {
	public static readonly type = "[Worker Payments] update worker payment";
	constructor(
		public payload: WorkerPayment,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class RemoveWorkerPayment {
	public static readonly type = "[Worker Payments] remove worker payment";
	constructor(
		public paymentId: string,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void,
	) { }
}

/// DOCUMENTS ///
export class LoadWorkerDocuments {
	public static readonly type = "[Workers] load worker documents";
	constructor(
    public workerId: number,
		public maxResults: number,
		public skipCount: number
  ) {}
}

export class AddWorkerDocument {
	public static readonly type = "[Workers] add worker document";
	constructor(
		public payload: WorkerDocument,
		public files: Array<File>,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class UpdateWorkerDocument {
	public static readonly type = "[Workers] update worker document";
	constructor(
		public payload: WorkerDocument,
		public files: Array<File>,
		public filesToDelete: Array<string>,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

export class RemoveWorkerDocument {
	public static readonly type = "[Workers] remove worker document";
	constructor(
    public documentId: string, 
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) {}
}

//// OTHERS ////
export class GetWorkerCommunications {
	public static readonly type = "[Worker] get worker communications";
	constructor(
    public workerId: number
  ) {}
}

export class GetWorkerProfileHistory {
	public static readonly type = "[Workers] get worker profile history";
	constructor(
    public workerId: number
  ) {}
}

export class LoadWorkerTabs {
	static readonly type = "[Workers] load worker tabs";
	constructor(
		public status: WorkerProfileStatus
	) {}
}

export class UpdateTabsAddWorker {
	static readonly type = "[Workers] update tabs";
	constructor(
    public profile: boolean, 
    public workerInformation: boolean
  ) {}
}